import {
  checkCompanyEarningsExists,
  createCompanyEarning,
  updateCompanyEarning,
  fetchCompanyEarning,
  deleteCompanyEarning,
  addHourlyRate,
  getHourlyRate,
  checkHourlyRateExists,
  getEarningGroups,
  checkIfUsedAnywhere,
  startCopyOperation,
  getCompanyEarningSnapshot,
  checkEarningsCalculationMethodChange
} from ".";
import {
  editEntityData,
  clearEntityData
} from "app/common/reducers/entityData";
import {
  editEntityErrors,
  clearEntityErrors
} from "app/common/reducers/entityErrors";
import { Fields } from "component-library/earnings-setup/fieldNames";
import { showToast } from "app/common/reducers/toast";
import { setLoader } from "app/actions/LoaderActions";
import { setFilterOptions } from "app/common/reducers/filters";
import { HOURLY_RATES, EARNING_GROUPS } from "component-library/earnings-setup";
import { BigNumber } from "bignumber.js";
import {
  ERROR_TOAST,
  RED_TOAST,
  PERCENT_AMOUNT_MIN,
  PERCENT_AMOUNT_MAX,
  DOLLAR_AMOUNT_MIN,
  DOLLAR_AMOUNT_MAX,
  PERCENT_FIELD_VALUE_ERROR,
  DOLLAR_FIELD_VALUE_ERROR,
  PIECE_RATE,
  DOLLAR_AMOUNT,
  HOURLY_RATE,
  PIECE_RATE_HOUR
} from "./../pages/Constants";
import { setFormUpdate } from "app/actions/FormUpdateAction";
import { loadEntitySnapshot } from "app/common/reducers/entitySnapshot";
import { CLIENT_ADMIN } from "./../../app/common/CompanyConstants";
import {
  GROSS_EARNINGS,
  TAXABLE_EARNINGS,
  DISPOSABLE_EARNINGS,
  RETIREMENT_EARNINGS,
  PERCENTAGE_BASED_EARNINGS
} from "earnings-group/util/Constants";
import { removeCommas, numberWithCommas } from "earnings/util";
import { loadDefault } from "earnings/pages/SystemEarningsDefaults";
import {
  EARNINGS_TRACKING_HOURS,
  EARNINGS_TRACKING_DOLLARS,
  EARNINGS_PAID_NEGATIVE100_ERROR,
  PREMIUM_PERCENT_PAID_NEGATIVE100_ERROR
} from "earnings/pages/company-level/CompanyConstants";
import { authCheck } from "app/actions/AuthCheck";

export const fetchAndSetCompanyEarning = (
  id,
  callback = () => { }
) => dispatch => {
  dispatch(editEntityData({ status: true }));
  dispatch(clearEntityData());
  dispatch(clearEntityErrors());

  fetchCompanyEarning(id)
    .then(res => {
      const { DataCount, Data = [] } = res.data;
      if (DataCount === 1 && Data[0] !== false) {
        let Response = Data[0];
        if (Response[Fields.w2Boxcode]) {
          Response[Fields.hasW2BoxCode] = true;
        } else {
          Response[Fields.hasW2BoxCode] = false;
        }
        if (
          Response[Fields.isDollarPremiumType] ||
          Response[Fields.isPercentPremiumType]
        ) {
          Response[Fields.isPremium] = true;
        } else {
          Response[Fields.isPremium] = false;
        }
        if (
          Response[Fields.dollarPremiumAmount] == null ||
          Response[Fields.dollarPremiumAmount] === 0
        ) {
          Response[Fields.dollarPremiumAmount] = "";
        } else {
          Response[Fields.dollarPremiumAmount] = numberWithCommas(
            String(Response[Fields.dollarPremiumAmount])
          );
        }
        if (Response[Fields.percentPremiumAmount] == null) {
          Response[Fields.percentPremiumAmount] = "";
        } else {
          Response[Fields.percentPremiumAmount] = numberWithCommas(
            String(Response[Fields.percentPremiumAmount])
          );
        }

        if (Response[Fields.pieceRate] == null) {
          Response[Fields.pieceRate] = "";
        } else {
          Response[Fields.pieceRate] = numberWithCommas(
            String(Response[Fields.pieceRate])
          );
        }

        if (Response[Fields.hourlyPayImported] != null) {
          Response[Fields.hourlyPay] = false;
        } else {
          Response[Fields.hourlyPay] = true;
        }
        if (Response[Fields.earningGroups].length > 0) {
          let Options = Response[Fields.earningGroups];
          let Options_obj = {};
          Options.forEach(item => {
            Options_obj[item] = true;
          });
          Response[Fields.earningGroups] = Options_obj;
        } else {
          let Options = [];
          if (Response[Fields.paidToEmployee] != null) {
            if (Response[Fields.paidToEmployee]) {
              if (Response[Fields.categoryId] !== 4) {
                Options.push(String(DISPOSABLE_EARNINGS));
                Options.push(String(GROSS_EARNINGS));
              }
              if (Response[Fields.earningsTaxable]) {
                Options.push(String(TAXABLE_EARNINGS));
              }
            } else {
              if (Response[Fields.earningsTaxable]) {
                Options.push(String(TAXABLE_EARNINGS));
              }
            }
            if (Options.length > 0) {
              let defaultOptions = Options;
              let Options_obj = {};
              defaultOptions.forEach(item => {
                Options_obj[item] = true;
              });
              Response[Fields.earningGroups] = Options_obj;
            }
          }
        }
        if (Response[Fields.includeInAccrue] === null) {
          Response[Fields.includeInAccrue] = loadDefault(
            Fields.includeInAccrue,
            Response
          );
        }
        if (Response[Fields.includeInTimeOff] === null) {
          Response[Fields.includeInTimeOff] = loadDefault(
            Fields.includeInTimeOff,
            Response
          );
        }
        if (Response[Fields.includeIfAccrualPlanAssigned] === null) {
          Response[Fields.includeIfAccrualPlanAssigned] = loadDefault(
            Fields.includeIfAccrualPlanAssigned,
            Response
          );
        }
        if (Response[Fields.referenceCodeImport] === null) {
          Response[Fields.hasImportCode] = false;
        } else {
          Response[Fields.hasImportCode] = true;
        }

        if (Response[Fields.includeInTimeAttendance] == null) {
          Response[Fields.includeInTimeAttendance] = loadDefault(
            Fields.includeInTimeAttendance,
            Response
          );
        }
        if (Response[Fields.subtractHoursPerPayPeriod] == null) {
          Response[Fields.subtractHoursPerPayPeriod] = loadDefault(
            Fields.subtractHoursPerPayPeriod,
            Response
          );
        }
        if (Response[Fields.countsTowardsOvertime] == null) {
          Response[Fields.countsTowardsOvertime] = loadDefault(
            Fields.countsTowardsOvertime,
            Response
          );
        }
        if (Response[Fields.includeInRegularRate] === null) {
          Response[Fields.includeInRegularRate] = loadDefault(
            Fields.includeInRegularRate,
            Response
          );
        }
        if (Response[Fields.regularRateOfPay] === null) {
          Response[Fields.regularRateOfPay] = loadDefault(
            Fields.regularRateOfPay,
            Response
          );
        }

        if (Response[Fields.isProductiveHours] === null) {
          Response[Fields.isProductiveHours] = loadDefault(
            Fields.isProductiveHours,
            Response
          );
        }
        if (Response[Fields.workersCompensation] === null) {
          Response[Fields.workersCompensation] = loadDefault(
            Fields.workersCompensation,
            Response
          );
        }
        Response[Fields.canChangeIncludeInTimeAttendance] = true;
        if (Response[Fields.includeInTimeAttendance]) {
          if (
            Response[Fields.payrollGroup] ||
            Response[Fields.deptTransfer] ||
            Response[Fields.clientProjectTransfer] ||
            Response[Fields.inUse]
          ) {
            Response[Fields.canChangeIncludeInTimeAttendance] = false;
          }
        }
        if (Response[Fields.earningsTrack] === EARNINGS_TRACKING_DOLLARS) {
          if (Response[Fields.calculationMethodId] == null) {
            Response[Fields.calculationMethodId] = DOLLAR_AMOUNT;
          }
        }
        if (
          Response[Fields.companyPayTipped] &&
          Response[Fields.includeInTipShortfall] == null
        ) {
          Response[Fields.includeInTipShortfall] = loadDefault(
            Fields.includeInTipShortfall,
            Response
          );
        }
        if (
          Response[Fields.companyPayTipped] &&
          Response[Fields.overtimePaidTipped] == null
        ) {
          Response[Fields.overtimePaidTipped] = loadDefault(
            Fields.overtimePaidTipped,
            Response
          );
        }

        Response["status"] = false;
        Response[Fields.prevData] = {
          ...Response
        };
        dispatch(editEntityData(Response));
        callback(Response, false);
      }
    })
    .catch(error => {
      dispatch(authCheck(error));
    });
};
export const deleteEarningAndReload = (
  id,
  earningsName,
  successCallback
) => dispatch =>
    deleteCompanyEarning(id).then(() => {
      let message = "'" + earningsName + "' has been deleted.";
      dispatch(showToast(message, "success", false, "delete", "EARNINGS"));
      successCallback();
      dispatch(fetchCompanyEarning(false, false));
    });

const validateDescription = data => {
  let errors = {};
  if (!data[Fields.comp_name]) {
    errors[Fields.comp_name] = "This is a required field.";
  } else {
    let value = data[Fields.comp_name].trim();
    if (!value) {
      errors[Fields.comp_name] = "This is a required field.";
    }
  }
  if (data[Fields.comp_name] && data[Fields.comp_name].trim().length > 35) {
    errors[Fields.comp_name] =
      "Earnings Name must have less than 35 characters";
  }
  if (!data[Fields.categoryId] && !data[Fields.categoryName]) {
    errors[Fields.categoryId] = "This is a required field.";
  }
  if (data[Fields.categoryId]) {
    if (!data[Fields.subcategoryId] && !data[Fields.subcategoryName]) {
      errors[Fields.subcategoryId] = "This is a required field.";
    }
    if (data[Fields.subcategoryId]) {
      if (data[Fields.paidToEmployee] == null) {
        errors[Fields.paidToEmployee] = "This is a required field.";
      }
      if (data[Fields.earningsTaxable] == null) {
        errors[Fields.earningsTaxable] = "This is a required field.";
      }
      if (
        data[Fields.subcategoryId] === 29 &&
        !data[Fields.earningsTaxable] &&
        data[Fields.disabilityPaymentDirectlyByState] == null
      ) {
        errors[Fields.disabilityPaymentDirectlyByState] =
          "This is a required field.";
      }
      if (data[Fields.earningsTaxable]) {
        if (
          !data[Fields.increaseFUTAWages] &&
          !data[Fields.increaseLocalWages] &&
          !data[Fields.increaseMedicareWages] &&
          !data[Fields.increaseSDIWages] &&
          !data[Fields.increaseSocialSecurityWages] &&
          !data[Fields.increaseStateWages] &&
          !data[Fields.increaseFederalWages] &&
          !data[Fields.increaseSUTAWages]
        ) {
          errors["advancedTaxOptions"] =
            "At least one of these boxes should be checked.";
        }
      }
      if (
        !data[Fields.earningsTaxable] &&
        !data[Fields.paidToEmployee] &&
        data[Fields.earningsTrack] == null
      ) {
        errors[Fields.earningsTrack] = "This is a required field.";
      }
      if (
        data[Fields.taxesModified] &&
        data[Fields.earningsTaxable] &&
        (data[Fields.earningsChangeReason] === null ||
          data[Fields.earningsChangeReason] === undefined ||
          String(data[Fields.earningsChangeReason]).trim() === "")
      ) {
        errors[Fields.earningsChangeReason] = "This is a required field.";
      }
      if (
        data[Fields.hasW2BoxCode] == null ||
        data[Fields.hasW2BoxCode] === undefined
      ) {
        errors[Fields.hasW2BoxCode] = "This is a required field.";
      }
      if (data[Fields.hasW2BoxCode] && data[Fields.w2Boxcode] == null) {
        errors[Fields.w2Boxcode] = "This is a required field.";
      } else if (data[Fields.hasW2BoxCode] && data[Fields.w2Boxcode] != null) {
        let w2code = data[Fields.w2Boxcode].trim();
        if (!w2code) {
          errors[Fields.w2Boxcode] = "This is a required field.";
        }
      }
    }
  }

  return errors;
};

export const checkForDescriptionMandatoryFields = data => {
  const errors = validateDescription(data);
  return !!Object.keys(errors).length > 0;
};

export const validateAndSaveDescription = (
  data,
  successCallback
) => dispatch => {
  if (data[Fields.comp_name]) {
    data[Fields.comp_name] = data[Fields.comp_name].trim();
  }
  if (data[Fields.companyEarningsCode]) {
    data[Fields.companyEarningsCode] = data[Fields.companyEarningsCode].trim();
  }
  if (data[Fields.w2Boxcode]) {
    data[Fields.w2Boxcode] = data[Fields.w2Boxcode].trim();
  }
  const errors = validateDescription(data);
  if (data[Fields.calculationMethodId] === PIECE_RATE) {
    if (!data[Fields.earningsTaxable] && !data[Fields.paidToEmployee]) {
      errors[Fields.paidToEmployee] =
        "The calculation method currently selected (Piece Rate) is not supported for earnings that are not taxable and not paid.";
      errors[Fields.earningsTaxable] =
        "The calculation method currently selected (Piece Rate) is not supported for earnings that are not taxable and not paid.";
    }
  }
  if (data[Fields.earningsTrack]) {
    if (data[Fields.calculationMethodId] === DOLLAR_AMOUNT) {
      if (
        !data[Fields.earningsTaxable] &&
        !data[Fields.paidToEmployee] &&
        data[Fields.earningsTrack] === EARNINGS_TRACKING_HOURS
      ) {
        errors[Fields.earningsTrack] =
          "The calculation method currently selected (Dollar Amount) is not supported for earnings that are used to track hours.";
      }
    }
    if (data[Fields.calculationMethodId] === HOURLY_RATE) {
      if (
        !data[Fields.earningsTaxable] &&
        !data[Fields.paidToEmployee] &&
        data[Fields.earningsTrack] === EARNINGS_TRACKING_DOLLARS
      ) {
        errors[Fields.earningsTrack] =
          "The calculation method currently selected (Hourly Rate) is not supported for earnings that are used to track dollars.";
      }
    }
    if (data[Fields.calculationMethodId] === PIECE_RATE_HOUR) {
      if (
        !data[Fields.earningsTaxable] &&
        !data[Fields.paidToEmployee] &&
        data[Fields.earningsTrack] === "Dollars"
      ) {
        errors[Fields.earningsTrack] =
          "The calculation method currently selected (Piece Rate Hour) is not supported for earnings that are used to track dollars.";
      }
    }
  }
  if (
    data[Fields.calculationMethodId] === HOURLY_RATE &&
    BigNumber(data[Fields.percentPremiumAmount]).isEqualTo("-100") &&
    data[Fields.paidToEmployee]
  ) {
    errors[Fields.paidToEmployee] = EARNINGS_PAID_NEGATIVE100_ERROR;
  }

  if (Object.keys(errors).length > 0) {
    dispatch(editEntityErrors(errors)); // TODO (ashish): Make sure this works
    dispatch(showToast(ERROR_TOAST, RED_TOAST, false, "default", "EARNINGS"));
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    dispatch(setLoader(false));
    return;
  }
  checkCompanyEarningsExists(data[Fields.comp_name])
    .then(res => {
      const { DataCount, Data = [], Errors, ErrorCount } = res.data;
      if (DataCount === 1 && Data[0] === false) {
        if (!data[Fields.stepID] || data.SystemEarningId !== 0) {
          data[Fields.stepID] = 1;
          data[Fields.stepName] = "CompanyEarningsDescriptionPage";
        }

        if (data[Fields.pieceRate] == '') data[Fields.pieceRate] = null;
        if (data[Fields.percentPremiumAmount] == '') data[Fields.percentPremiumAmount] = null;
        if (data[Fields.dollarPremiumAmount] == '') data[Fields.dollarPremiumAmount] = null;

        createCompanyEarning(data, successCallback)
          .then(res => {
            const { Data = [], Errors, ErrorCount } = res.data;
            if (Data !== null) {
              const {
                CompanyEarningId,
                UIDraftCompletionStage,
                CompanyEarningName
              } = Data[0];
              if (CompanyEarningId) {
                dispatch(setFormUpdate(false));

                successCallback(CompanyEarningId);
                if (UIDraftCompletionStage < 5) {
                  let Toast = `'${CompanyEarningName}' has been saved. We’ve marked this earnings as in progress for you to work on later.`;
                  dispatch(
                    showToast(Toast, "success", false, "inprogress", "EARNINGS")
                  );
                } else {
                  let Toast = "'" + CompanyEarningName + "' has been updated. ";
                  dispatch(
                    showToast(Toast, "success", false, "default", "EARNINGS")
                  );
                }
              }
            } else if (
              ErrorCount === 1 &&
              Errors[0].Tag === "EARNINGS_CODE_EXIST"
            ) {
              let message = "Earnings code already exists";
              if (Errors[0].MessageList.length > 0) {
                message = Errors[0].MessageList[0];
                data[Fields.stepID] = 0;
              }
              dispatch(
                editEntityErrors({
                  [Fields.companyEarningsCode]: message
                })
              );
              dispatch(setLoader(false));
              dispatch(showToast(ERROR_TOAST, RED_TOAST));
              document.body.scrollTop = 0;
              document.documentElement.scrollTop = 0;
            } else if (
              ErrorCount === 1 &&
              Errors[0].Tag === "EARNINGS_CODE_EXIST_WITH_IMPORTCODE"
            ) {
              let message =
                "An import code already exists with this earnings code.";
              if (Errors[0].MessageList.length > 0) {
                message = Errors[0].MessageList[0];
                data[Fields.stepID] = 0;
              }
              dispatch(
                editEntityErrors({
                  [Fields.companyEarningsCode]: message
                })
              );
              dispatch(setLoader(false));
              dispatch(showToast(ERROR_TOAST, RED_TOAST));
              document.body.scrollTop = 0;
              document.documentElement.scrollTop = 0;
            }
          })
          .catch(error => {
            dispatch(authCheck(error));
          });
      } else if (ErrorCount === 1 && Errors[0].Tag === "ALREADY_EXISTS") {
        let message = "An earnings already exists with this name";
        if (Errors[0].MessageList.length > 0) {
          message = Errors[0].MessageList[0];
        }
        dispatch(
          editEntityErrors({
            [Fields.comp_name]: message
          })
        );
        dispatch(setLoader(false));
        dispatch(showToast(ERROR_TOAST, RED_TOAST));
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }
    })
    .catch(error => {
      dispatch(authCheck(error));
    });
};
export const validateAndUpdateDescription = (
  Id,
  data,
  successCallback
) => dispatch => {
  if (data[Fields.comp_name]) {
    data[Fields.comp_name] = data[Fields.comp_name].trim();
  }
  if (data[Fields.w2Boxcode]) {
    data[Fields.w2Boxcode] = data[Fields.w2Boxcode].trim();
  }
  let Groups = [];
  let groupObject = data[Fields.earningGroups];
  for (var key in groupObject) {
    if (groupObject[key]) {
      Groups.push(key);
    }
  }
  const errors = validateDescription(data);
  if (data[Fields.calculationMethodId] === PIECE_RATE) {
    if (!data[Fields.earningsTaxable] && !data[Fields.paidToEmployee]) {
      errors[Fields.paidToEmployee] =
        "The calculation method currently selected (Piece Rate) is not supported for earnings that are not taxable and not paid.";
      errors[Fields.earningsTaxable] =
        "The calculation method currently selected (Piece Rate) is not supported for earnings that are not taxable and not paid.";
    }
  }
  if (data[Fields.earningsTrack]) {
    if (data[Fields.calculationMethodId] === DOLLAR_AMOUNT) {
      if (
        !data[Fields.earningsTaxable] &&
        !data[Fields.paidToEmployee] &&
        data[Fields.earningsTrack] === EARNINGS_TRACKING_HOURS
      ) {
        errors[Fields.earningsTrack] =
          "The calculation method currently selected (Dollar Amount) is not supported for earnings that are used to track hours.";
      }
    }
    if (data[Fields.calculationMethodId] === HOURLY_RATE) {
      if (
        !data[Fields.earningsTaxable] &&
        !data[Fields.paidToEmployee] &&
        data[Fields.earningsTrack] === EARNINGS_TRACKING_DOLLARS
      ) {
        errors[Fields.earningsTrack] =
          "The calculation method currently selected (Hourly Rate) is not supported for earnings that are used to track dollars.";
      }
    }
    if (data[Fields.calculationMethodId] === PIECE_RATE_HOUR) {
      if (
        !data[Fields.earningsTaxable] &&
        !data[Fields.paidToEmployee] &&
        data[Fields.earningsTrack] === "Dollars"
      ) {
        errors[Fields.earningsTrack] =
          "The calculation method currently selected (Piece Rate Hour) is not supported for earnings that are used to track dollars.";
      }
    }
  }
  if (
    data[Fields.calculationMethodId] === HOURLY_RATE &&
    BigNumber(data[Fields.percentPremiumAmount]).isEqualTo("-100") &&
    data[Fields.paidToEmployee]
  ) {
    errors[Fields.paidToEmployee] = EARNINGS_PAID_NEGATIVE100_ERROR;
  }

  if (Object.keys(errors).length > 0) {
    dispatch(editEntityErrors(errors)); // TODO (ashish): Make sure this works
    dispatch(showToast(ERROR_TOAST, RED_TOAST));
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    dispatch(setLoader(false));
    return;
  }
  checkCompanyEarningsExists(data[Fields.comp_name], Id)
    .then(res => {
      const { DataCount, Data = [], Errors, ErrorCount } = res.data;
      if (DataCount === 1 && Data[0] === false) {
        let request = [
          {
            Operation: "update",
            PathOrKey: Fields.comp_name,
            Value: data[Fields.comp_name].trim()
          },
          {
            Operation: "update",
            PathOrKey: Fields.categoryId,
            Value: data[Fields.categoryId]
          },
          {
            Operation: "update",
            PathOrKey: Fields.subcategoryId,
            Value: data[Fields.subcategoryId]
          },
          {
            Operation: "update",
            PathOrKey: Fields.paidToEmployee,
            Value: data[Fields.paidToEmployee]
          },
          {
            Operation: "update",
            PathOrKey: Fields.earningsTaxable,
            Value: data[Fields.earningsTaxable]
          },
          {
            Operation: "update",
            PathOrKey: Fields.disabilityPaymentDirectlyByState,
            Value: data[Fields.disabilityPaymentDirectlyByState]
          },
          {
            Operation: "update",
            PathOrKey: Fields.premiumType,
            Value: data[Fields.premiumType]
          },
          {
            Operation: "update",
            PathOrKey: Fields.increaseFederalWages,
            Value: data[Fields.increaseFederalWages]
          },
          {
            Operation: "update",
            PathOrKey: Fields.increaseSocialSecurityWages,
            Value: data[Fields.increaseSocialSecurityWages]
          },
          {
            Operation: "update",
            PathOrKey: Fields.increaseMedicareWages,
            Value: data[Fields.increaseMedicareWages]
          },
          {
            Operation: "update",
            PathOrKey: Fields.increaseStateWages,
            Value: data[Fields.increaseStateWages]
          },
          {
            Operation: "update",
            PathOrKey: Fields.increaseLocalWages,
            Value: data[Fields.increaseLocalWages]
          },
          {
            Operation: "update",
            PathOrKey: Fields.increaseSUTAWages,
            Value: data[Fields.increaseSUTAWages]
          },
          {
            Operation: "update",
            PathOrKey: Fields.increaseFUTAWages,
            Value: data[Fields.increaseFUTAWages]
          },
          {
            Operation: "update",
            PathOrKey: Fields.increaseSDIWages,
            Value: data[Fields.increaseSDIWages]
          },
          {
            Operation: "update",
            PathOrKey: Fields.excludeFromFederalTax,
            Value: data[Fields.excludeFromFederalTax]
          },
          {
            Operation: "update",
            PathOrKey: Fields.excludeFromStateTax,
            Value: data[Fields.excludeFromStateTax]
          },
          {
            Operation: "update",
            PathOrKey: Fields.excludeFromLocalTax,
            Value: data[Fields.excludeFromLocalTax]
          },
          {
            Operation: "update",
            PathOrKey: Fields.w2Boxcode,
            Value: data[Fields.w2Boxcode]
          },
          {
            Operation: "update",
            PathOrKey: Fields.earningsChangeReason,
            Value: data[Fields.earningsChangeReason]
          },
          {
            Operation: "update",
            PathOrKey: Fields.earningsTrack,
            Value: data[Fields.earningsTrack]
          },

          {
            Operation: "update",
            PathOrKey: Fields.earningGroups,
            Value: Groups
          },
          //? Calculation Fields Depending on Tracking
          {
            Operation: "update",
            PathOrKey: Fields.hourlyPay,
            Value: data[Fields.hourlyPay]
          },
          {
            Operation: "update",
            PathOrKey: Fields.hourlyPayImported,
            Value: data[Fields.hourlyPayImported]
          },
          {
            Operation: "update",
            PathOrKey: Fields.customRate,
            Value: data[Fields.customRate]
          },
          {
            Operation: "update",
            PathOrKey: Fields.overtimeType,
            Value: data[Fields.overtimeType]
          },
          {
            Operation: "update",
            PathOrKey: Fields.overtimeFactor,
            Value: data[Fields.overtimeFactor]
          },
          {
            Operation: "update",
            PathOrKey: Fields.isDollarPremiumType,
            Value: data[Fields.isDollarPremiumType]
          },
          {
            Operation: "update",
            PathOrKey: Fields.dollarPremiumAmount,
            Value: removeCommas(data[Fields.dollarPremiumAmount])
          },
          {
            Operation: "update",
            PathOrKey: Fields.isPercentPremiumType,
            Value: data[Fields.isPercentPremiumType]
          },
          {
            Operation: "update",
            PathOrKey: Fields.percentPremiumAmount,
            Value: removeCommas(data[Fields.percentPremiumAmount])
          }
        ];
        if (data[Fields.stepID] < 1) {
          request.push({
            Operation: "update",
            PathOrKey: Fields.stepID,
            Value: 2
          });
          request.push({
            Operation: "update",
            PathOrKey: Fields.stepName,
            Value: "CompanyEarningsDescription"
          });
        }

        updateCompanyEarning(Id, request)
          .then(res => {
            const { DataCount, Data = [], Id } = res.data;
            if (DataCount === 1 && Data[0] != null) {
              dispatch(setFormUpdate(false));
              successCallback(Id);
              const { UIDraftCompletionStage, CompanyEarningName } = Data[0];
              if (UIDraftCompletionStage < 5) {
                let Toast = `'${CompanyEarningName}' has been saved. We’ve marked this earnings as in progress for you to work on later.`;
                dispatch(
                  showToast(Toast, "success", false, "inprogress", "EARNINGS")
                );
              } else {
                let Toast = "'" + CompanyEarningName + "' has been updated. ";
                dispatch(
                  showToast(Toast, "success", false, "default", "EARNINGS")
                );
              }
            } else {
            }
            dispatch(setLoader(false));
          })
          .catch(error => {
            dispatch(authCheck(error));
          });
      } else if (ErrorCount === 1 && Errors[0].Tag === "ALREADY_EXISTS") {
        let message = "An earnings already exists with this name";
        if (Errors[0].MessageList.length > 0) {
          message = Errors[0].MessageList[0];
        }

        dispatch(
          editEntityErrors({
            [Fields.comp_name]: message
          })
        );
        dispatch(showToast(ERROR_TOAST, RED_TOAST));
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        dispatch(setLoader(false));
      }
    })
    .catch(error => {
      dispatch(authCheck(error));
    });
};

const validateCalculation = data => {
  let errors = {};
  if (!data[Fields.calculationMethodId]) {
    errors[Fields.calculationMethodId] = "This is a required field.";
  }
  if (data[Fields.calculationMethodId] === 3 && !data[Fields.pieceRate]) {
    errors[Fields.pieceRate] = "This is a required field.";
  }
  if (
    data[Fields.calculationMethodId] === 2 &&
    (data[Fields.hourlyPay] === undefined || data[Fields.hourlyPay] === null)
  ) {
    errors[Fields.hourlyPay] = "This is a required field.";
  }
  if (
    data[Fields.hourlyPay] === false &&
    (data[Fields.hourlyPayImported] === undefined ||
      data[Fields.hourlyPayImported] === null)
  ) {
    errors[Fields.hourlyPayImported] = "This is a required field.";
  }
  if (
    data[Fields.calculationMethodId] === 2 &&
    data[Fields.customRate] === null &&
    !data[Fields.hourlyPayImported] &&
    !data[Fields.hourlyPay]
  ) {
    errors[Fields.customRate] = "This is a required field.";
  }
  if (
    [1, 2, 4].includes(data[Fields.calculationMethodId]) &&
    (data[Fields.overtimeType] === undefined ||
      data[Fields.overtimeType] === null)
  ) {
    errors[Fields.overtimeType] = "This is a required field.";
  }
  if (
    data[Fields.overtimeType] &&
    !data[Fields.overtimeFactor] &&
    data[Fields.calculationMethodId] !== 1
  ) {
    errors[Fields.overtimeFactor] = "This is a required field.";
  }

  if (
    data[Fields.isPremium] &&
    (!data[Fields.isPercentPremiumType] && !data[Fields.isDollarPremiumType])
  ) {
    errors[Fields.premiumType] = "This is a required field.";
  }

  if (data[Fields.isPercentPremiumType]) {
    if (
      !data[Fields.percentPremiumAmount] ||
      data[Fields.percentPremiumAmount] == null
    ) {
      errors[Fields.percentPremiumAmount] = "This is a required field.";
    } else {
      let bigNumValue = BigNumber(
        removeCommas(data[Fields.percentPremiumAmount])
      );
      let bigNumMaxValue = BigNumber(PERCENT_AMOUNT_MAX);
      let bigNumMinValue = BigNumber(PERCENT_AMOUNT_MIN);

      if (bigNumValue.isLessThan(bigNumMinValue)) {
        errors[Fields.percentPremiumAmount] = PERCENT_FIELD_VALUE_ERROR;
      } else if (bigNumValue.isGreaterThan(bigNumMaxValue)) {
        errors[Fields.percentPremiumAmount] = PERCENT_FIELD_VALUE_ERROR;
      }
    }
  }

  if (data[Fields.isDollarPremiumType]) {
    if (
      data[Fields.dollarPremiumAmount] == null ||
      data[Fields.dollarPremiumAmount] === ""
    )
      errors[Fields.dollarPremiumAmount] = "This is a required field.";
    else {
      var bigNumValue = BigNumber(
        removeCommas(data[Fields.dollarPremiumAmount])
      );
      var bigNumMaxValue = BigNumber(DOLLAR_AMOUNT_MAX);
      var bigNumMinValue = BigNumber(DOLLAR_AMOUNT_MIN);
      if (bigNumValue.isLessThanOrEqualTo(bigNumMinValue)) {
        errors[Fields.dollarPremiumAmount] = DOLLAR_FIELD_VALUE_ERROR;
      } else if (bigNumValue.isGreaterThan(bigNumMaxValue)) {
        errors[Fields.dollarPremiumAmount] = DOLLAR_FIELD_VALUE_ERROR;
      }
    }
  }

  return errors;
};
export const validateAndUpdateCalculation = (
  id,
  data,
  successCallback
) => dispatch => {
  const errors = validateCalculation(data);
  if (data[Fields.stepID] >= 4) {
    if (
      data[Fields.calculationMethodId] === HOURLY_RATE &&
      data[Fields.countsTowardsOvertime] &&
      data[Fields.prevData][Fields.countsTowardsOvertime] &&
      data[Fields.overtimeType]
    ) {
      errors[Fields.overtimeType] = "Earnings counts towards overtime.";
    }
  }

  if (
    data[Fields.paidToEmployee] &&
    BigNumber(data[Fields.percentPremiumAmount]).isEqualTo("-100")
  ) {
    errors[
      Fields.percentPremiumAmount
    ] = PREMIUM_PERCENT_PAID_NEGATIVE100_ERROR;
  }
  if (Object.keys(errors).length > 0) {
    dispatch(editEntityErrors(errors));
    dispatch(showToast(ERROR_TOAST, RED_TOAST));
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    dispatch(setLoader(false));
    return;
  }
  checkEarningsCalculationMethodChange(data)
    .then(res => {
      const { ErrorCount, Errors, Id } = res.data;
      if (data[Fields.stepID] >= 4) {
        if (
          data[Fields.prevData][Fields.calculationMethodId] === HOURLY_RATE &&
          data[Fields.calculationMethodId] !== HOURLY_RATE &&
          ErrorCount > 0
        ) {
          let errors = Errors.filter(item => item.Tag === "IN_USED");
          successCallback(Id, true, errors[0].MessageList);
          return;
        } else if (
          data[Fields.prevData][Fields.calculationMethodId] ===
          PIECE_RATE_HOUR &&
          data[Fields.calculationMethodId] !== PIECE_RATE_HOUR &&
          ErrorCount > 0
        ) {
          let errors = Errors.filter(item => item.Tag === "IN_USED");
          successCallback(Id, true, errors[0].MessageList);
          return;
        } else if (
          data[Fields.overtimeType] === false &&
          data[Fields.overtimePaidTipped]
        ) {
          let errors = Errors.filter(item => item.Tag === "IN_USED");
          successCallback(Id, true, errors[0].MessageList);
          return;
        }
      }

      let request = [
        {
          Operation: "update",
          PathOrKey: Fields.calculationMethodId,
          Value: data[Fields.calculationMethodId]
        },
        {
          Operation: "update",
          PathOrKey: Fields.calculationMethodName,
          Value: data[Fields.calculationMethodName]
        },
        {
          Operation: "update",
          PathOrKey: Fields.hourlyPay,
          Value: data[Fields.hourlyPay]
        },
        {
          Operation: "update",
          PathOrKey: Fields.hourlyPayImported,
          Value: data[Fields.hourlyPayImported]
        },
        {
          Operation: "update",
          PathOrKey: Fields.customRate,
          Value: data[Fields.customRate]
        },
        {
          Operation: "update",
          PathOrKey: Fields.pieceRate,
          Value: removeCommas(data[Fields.pieceRate])
        },
        {
          Operation: "update",
          PathOrKey: Fields.overtimeType,
          Value: data[Fields.overtimeType]
        },
        {
          Operation: "update",
          PathOrKey: Fields.overtimeFactor,
          Value: data[Fields.overtimeFactor]
        },
        {
          Operation: "update",
          PathOrKey: Fields.isDollarPremiumType,
          Value: data[Fields.isDollarPremiumType]
        },
        {
          Operation: "update",
          PathOrKey: Fields.dollarPremiumAmount,
          Value: removeCommas(data[Fields.dollarPremiumAmount])
        },
        {
          Operation: "update",
          PathOrKey: Fields.isPercentPremiumType,
          Value: data[Fields.isPercentPremiumType]
        },
        {
          Operation: "update",
          PathOrKey: Fields.percentPremiumAmount,
          Value: removeCommas(data[Fields.percentPremiumAmount])
        },
        {
          Operation: "update",
          PathOrKey: Fields.subtractHoursPerPayPeriod,
          Value: data[Fields.subtractHoursPerPayPeriod]
        },
        {
          Operation: "update",
          PathOrKey: Fields.countsTowardsOvertime,
          Value: data[Fields.countsTowardsOvertime]
        },
        {
          Operation: "update",
          PathOrKey: Fields.regularRateOfPay,
          Value: data[Fields.regularRateOfPay]
        },
        {
          Operation: "update",
          PathOrKey: Fields.isProductiveHours,
          Value: data[Fields.isProductiveHours]
        },
        {
          Operation: "update",
          PathOrKey: Fields.includeInTimeOff,
          Value: data[Fields.includeInTimeOff]
        },
        {
          Operation: "update",
          PathOrKey: Fields.includeInAccrue,
          Value: data[Fields.includeInAccrue]
        },
        {
          Operation: "update",
          PathOrKey: Fields.includeIfAccrualPlanAssigned,
          Value: data[Fields.includeIfAccrualPlanAssigned]
        }
      ];
      if (data[Fields.stepID] < 2) {
        request.push({
          Operation: "update",
          PathOrKey: Fields.stepID,
          Value: 2
        });
        request.push({
          Operation: "update",
          PathOrKey: Fields.stepName,
          Value: "SystemEarningsCalculationMethod"
        });
      }
      updateCompanyEarning(id, request)
        .then(res => {
          const { DataCount, Data = [], Id } = res.data;
          if (DataCount === 1 && Data[0] != null) {
            dispatch(setFormUpdate(false));
            successCallback(Id);
            const { UIDraftCompletionStage, CompanyEarningName } = Data[0];
            if (UIDraftCompletionStage < 5) {
              let Toast = `'${CompanyEarningName}' has been saved. We’ve marked this earnings as in progress for you to work on later.`;
              dispatch(
                showToast(Toast, "success", false, "inprogress", "EARNINGS")
              );
            } else {
              let Toast = "'" + CompanyEarningName + "' has been updated. ";
              dispatch(
                showToast(Toast, "success", false, "default", "EARNINGS")
              );
            }
          }
          dispatch(setLoader(false));
        })
        .catch(error => {
          dispatch(authCheck(error));
        });
    })
    .catch(error => {
      dispatch(authCheck(error));
    });
};

export const fetchHourlyRate = (data, callback = () => { }) => dispatch => {
  dispatch(setFilterOptions(HOURLY_RATES, [], true));

  return getHourlyRate().then(res => {
    const { DataCount, Data = [] } = res.data;
    if (DataCount > 0 && Data.length > 0) {
      const options = Data.map(
        ({
          CompanyEarningHourlyRateId,
          CompanyEarningHourlyRateName,
          CompanyEarningHourlyRateDescription
        }) => ({
          value: CompanyEarningHourlyRateId,
          label: CompanyEarningHourlyRateName,
          id: CompanyEarningHourlyRateId,
          description: CompanyEarningHourlyRateDescription
        })
      );
      dispatch(setFilterOptions(HOURLY_RATES, options, false));
      callback();
    } else {
      dispatch(setFilterOptions(HOURLY_RATES, [], false));

      Promise.resolve();
    }
  });
};

const validateHourlyRate = data => {
  let errors = {};
  if (
    !data[Fields.rateName] ||
    data[Fields.rateName] === null ||
    data[Fields.rateName] === ""
  ) {
    errors[Fields.rateName] = "This is a required field.";
  }
  return errors;
};
export const validateAndAddHourlyRate = (data, successCallback) => dispatch => {
  data[Fields.rateName] = data[Fields.rateName].trim();
  const errors = validateHourlyRate(data);
  if (Object.keys(errors).length > 0) {
    dispatch(editEntityErrors(errors));
    return;
  }
  checkHourlyRateExists(data[Fields.rateName]).then(res => {
    const { DataCount, Data = [], ErrorCount } = res.data;
    if (DataCount === 1 && Data[0] === false) {
      let request = {};
      request[Fields.rateName] = data[Fields.rateName];
      request[Fields.subscriber] = data[Fields.subscriber];
      addHourlyRate(request).then(res => {
        const { DataCount, Data = [] } = res.data;
        if (DataCount === 1 && Data[0] !== false) {
          successCallback();
        } else {
        }
      });
    } else if (ErrorCount === 1) {
      dispatch(
        editEntityErrors({
          [Fields.rateName]: "Hourly Rate name is already present"
        })
      );
    }
  });
};
const validatePayCycle = data => {
  let errors = {};
  if (!data[Fields.earningCycleId]) {
    errors[Fields.earningCycleId] = "This is a required field.";
  }
  if (data[Fields.earningCycleId] === 3 && !data[Fields.earningSubcycleId]) {
    errors[Fields.earningSubcycleId] = "This is a required field.";
  }
  if (
    data[Fields.earningCycleId] === 1 &&
    !data[Fields.firstSchedulePayroll] &&
    !data[Fields.secondSchedulePayroll] &&
    !data[Fields.thirdSchedulePayroll] &&
    !data[Fields.fourthSchedulePayroll] &&
    !data[Fields.fifthSchedulePayroll]
  ) {
    errors[Fields.payrollFrequency] = "This is a required field.";
  }
  return errors;
};

export const checkForPayCycleMandatoryFields = data => {
  const errors = validatePayCycle(data);
  return !!Object.keys(errors).length > 0;
};

export const validateAndUpdatePayCycle = (
  id,
  data,
  successCallback
) => dispatch => {
  const errors = validatePayCycle(data);
  if (Object.keys(errors).length > 0) {
    dispatch(editEntityErrors(errors));
    dispatch(showToast(ERROR_TOAST, RED_TOAST));
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    dispatch(setLoader(false));
    return;
  }

  let request = [
    {
      Operation: "update",
      PathOrKey: Fields.firstSchedulePayroll,
      Value: data[Fields.firstSchedulePayroll]
    },
    {
      Operation: "update",
      PathOrKey: Fields.secondSchedulePayroll,
      Value: data[Fields.secondSchedulePayroll]
    },
    {
      Operation: "update",
      PathOrKey: Fields.thirdSchedulePayroll,
      Value: data[Fields.thirdSchedulePayroll]
    },
    {
      Operation: "update",
      PathOrKey: Fields.fourthSchedulePayroll,
      Value: data[Fields.fourthSchedulePayroll]
    },
    {
      Operation: "update",
      PathOrKey: Fields.fifthSchedulePayroll,
      Value: data[Fields.fifthSchedulePayroll]
    },
    {
      Operation: "update",
      PathOrKey: Fields.earningCycleId,
      Value: data[Fields.earningCycleId]
    },
    {
      Operation: "update",
      PathOrKey: Fields.earningSubcycleId,
      Value: data[Fields.earningSubcycleId]
    }
  ];
  if (data[Fields.stepID] < 3) {
    request.push({
      Operation: "update",
      PathOrKey: Fields.stepID,
      Value: 3
    });
    request.push({
      Operation: "update",
      PathOrKey: Fields.stepName,
      Value: "SystemEarningsPayCycle"
    });
  }
  updateCompanyEarning(id, request)
    .then(res => {
      const { DataCount, Data = [], Id } = res.data;
      if (DataCount === 1 && Data[0] != null) {
        const { UIDraftCompletionStage, CompanyEarningName } = Data[0];
        dispatch(setFormUpdate(false));
        successCallback(Id);
        if (UIDraftCompletionStage < 5) {
          let Toast = `'${CompanyEarningName}' has been saved. We’ve marked this earnings as in progress for you to work on later.`;
          dispatch(
            showToast(Toast, "success", false, "inprogress", "EARNINGS")
          );
        } else {
          let Toast = "'" + CompanyEarningName + "' has been updated. ";
          dispatch(showToast(Toast, "success", false, "default", "EARNINGS"));
        }
      }
      dispatch(setLoader(false));
    })
    .catch(error => {
      dispatch(authCheck(error));
    });
};
export const isDisabled = (
  SystemEarningGroupId,
  data = {},
  userRole = 0,
  IsEarningGroupUsedInPayrollDeduction
) => {
  if (
    userRole === CLIENT_ADMIN &&
    IsEarningGroupUsedInPayrollDeduction &&
    data[Fields.inUse]
  ) {
    return true;
  }

  if (
    SystemEarningGroupId === 1 ||
    SystemEarningGroupId === 2 ||
    SystemEarningGroupId === 3
  ) {
    return true;
  }
};
function compare(a, b) {
  if (a.id < b.id) return -1;
  if (a.id > b.id) return 1;
  return 0;
}
export const fetchAndSetEarningGroups = (
  data,
  userRole,
  hardFetch = false
) => dispatch => {
  dispatch(setFilterOptions(EARNING_GROUPS, [], false));

  if (data || hardFetch) {
    return getEarningGroups()
      .then(res => {
        const { DataCount, Data = [] } = res.data;

        if (DataCount > 0 && Data.length > 0) {
          let filteredData = Data;
          if ([1, 3].includes(data[Fields.calculationMethodId])) {
            const EARNINGS_GROUPS = [
              GROSS_EARNINGS,
              DISPOSABLE_EARNINGS,
              TAXABLE_EARNINGS,
              RETIREMENT_EARNINGS,
              PERCENTAGE_BASED_EARNINGS
            ];
            filteredData = Data.filter(i =>
              EARNINGS_GROUPS.includes(i.SystemEarningGroupId)
            );
          }

          const options = filteredData.map(
            ({
              SystemEarningGroupId,
              Name,
              IsEarningGroupUsedInPayrollDeduction
            }) => ({
              optionId: SystemEarningGroupId,
              label: Name,
              id: SystemEarningGroupId,
              disabled: isDisabled(
                SystemEarningGroupId,
                data,
                userRole,
                IsEarningGroupUsedInPayrollDeduction
              )
            })
          );
          options.sort(compare);
          dispatch(setFilterOptions(EARNING_GROUPS, options, false));
        } else {
          return Promise.resolve();
        }
        dispatch(setLoader(false));
      })
      .catch(error => {
        dispatch(authCheck(error));
      });
  }
};
const validateOptions = data => {
  let errors = {};
  if (data[Fields.companyPayTipped] === null) {
    errors[Fields.companyPayTipped] = "This is a requred field";
  }
  if (
    data[Fields.companyPayTipped] &&
    data[Fields.includeInTipShortfall] === null
  ) {
    errors[Fields.includeInTipShortfall] = "This is a requred field";
  }

  if (
    data[Fields.companyPayTipped] &&
    data[Fields.calculationMethodId] === HOURLY_RATE &&
    data[Fields.overtimeType] &&
    data[Fields.overtimePaidTipped] === null
  ) {
    errors[Fields.overtimePaidTipped] = "This is a required field.";
  }
  //Accurals
  if (
    data[Fields.calculationMethodId] === HOURLY_RATE &&
    data[Fields.includeInAccrue] === null
  ) {
    errors[Fields.includeInAccrue] = "This is a required field.";
  }
  if (
    data[Fields.calculationMethodId] === HOURLY_RATE &&
    data[Fields.includeInTimeOff] === null
  ) {
    errors[Fields.includeInTimeOff] = "This is a required field.";
  }
  if (
    data[Fields.calculationMethodId] === HOURLY_RATE &&
    data[Fields.includeIfAccrualPlanAssigned] === null
  ) {
    errors[Fields.includeIfAccrualPlanAssigned] = "This is a required field.";
  }
  //Import
  if (data[Fields.hasImportCode] === null) {
    errors[Fields.hasImportCode] = "This is a required field.";
  }
  if (data[Fields.hasImportCode]) {
    let referenceCode = String(data[Fields.referenceCodeImport]).trim();
    if (referenceCode === "" || data[Fields.referenceCodeImport] === null)
      errors[Fields.referenceCodeImport] = "This is a required field.";
  }

  //Time and Attendance
  if (data[Fields.includeInTimeAttendance] === null) {
    errors[Fields.includeInTimeAttendance] = "This is a required field.";
  }
  if (
    data[Fields.includeInTimeAttendance] &&
    data[Fields.calculationMethodId] === HOURLY_RATE &&
    data[Fields.subtractHoursPerPayPeriod] === null
  ) {
    errors[Fields.subtractHoursPerPayPeriod] = "This is a required field.";
  }
  if (
    data[Fields.includeInTimeAttendance] &&
    data[Fields.calculationMethodId] === HOURLY_RATE &&
    !data[Fields.overtimeType] &&
    data[Fields.countsTowardsOvertime] === null
  ) {
    errors[Fields.countsTowardsOvertime] = "This is a required field.";
  }
  //Regular Rate
  if (
    (data[Fields.includeInTimeAttendance] ||
      (data[Fields.hasImportCode] && data[Fields.overtimeWeighted])) &&
    data[Fields.includeInRegularRate] === null
  ) {
    errors[Fields.includeInRegularRate] = "This is a required field.";
  }
  if (
    (data[Fields.includeInTimeAttendance] ||
      (data[Fields.hasImportCode] && data[Fields.overtimeWeighted])) &&
    data[Fields.calculationMethodId] === HOURLY_RATE &&
    data[Fields.regularRateOfPay] === null
  ) {
    errors[Fields.regularRateOfPay] = "This is a required field.";
  }
  //Reporting Options
  if (
    data[Fields.calculationMethodId] === HOURLY_RATE &&
    data[Fields.isProductiveHours] === null
  ) {
    errors[Fields.isProductiveHours] = "This is a required field.";
  }
  if (data[Fields.workersCompensation] === null) {
    errors[Fields.workersCompensation] = "This is a required field.";
  }

  if (
    data[Fields.optionsModified] &&
    (data[Fields.optionsNote] === null ||
      data[Fields.optionsNote] === undefined ||
      String(data[Fields.optionsNote]).trim() === "")
  ) {
    errors[Fields.optionsNote] = "This is a required field.";
  }
  return errors;
};
export const checkForMandatoryFields = data => {
  const errors = validateCalculation(data);
  return !!Object.keys(errors).length > 0;
};

export const checkForOptionMandatoryFields = data => {
  const errors = validateOptions(data);
  return !!Object.keys(errors).length > 0;
};
export const validateAndUpdateOptions = (
  id,
  data,
  successCallback
) => dispatch => {
  if (data[Fields.referenceCodeImport]) {
    data[Fields.referenceCodeImport] = data[Fields.referenceCodeImport].trim();
  }
  const errors = validateOptions(data);
  if (Object.keys(errors).length > 0) {
    dispatch(editEntityErrors(errors));
    dispatch(showToast(ERROR_TOAST, RED_TOAST));
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    dispatch(setLoader(false));
    return;
  }
  let Groups = [];
  let groupObject = data[Fields.earningGroups];
  for (var key in groupObject) {
    if (groupObject[key]) {
      Groups.push(key);
    }
  }
  let request = [
    {
      Operation: "update",
      PathOrKey: Fields.companyPayTipped,
      Value: data[Fields.companyPayTipped]
    },
    {
      Operation: "update",
      PathOrKey: Fields.includeInTipShortfall,
      Value: data[Fields.includeInTipShortfall]
    },
    {
      Operation: "update",
      PathOrKey: Fields.overtimePaidTipped,
      Value: data[Fields.overtimePaidTipped]
    },
    {
      Operation: "update",
      PathOrKey: Fields.includeInAccrue,
      Value: data[Fields.includeInAccrue]
    },
    {
      Operation: "update",
      PathOrKey: Fields.includeInTimeOff,
      Value: data[Fields.includeInTimeOff]
    },
    {
      Operation: "update",
      PathOrKey: Fields.includeIfAccrualPlanAssigned,
      Value: data[Fields.includeIfAccrualPlanAssigned]
    },
    {
      Operation: "update",
      PathOrKey: Fields.referenceCodeImport,
      Value: data[Fields.referenceCodeImport]
    },
    {
      Operation: "update",
      PathOrKey: Fields.includeInTimeAttendance,
      Value: data[Fields.includeInTimeAttendance]
    },
    {
      Operation: "update",
      PathOrKey: Fields.subtractHoursPerPayPeriod,
      Value: data[Fields.subtractHoursPerPayPeriod]
    },
    {
      Operation: "update",
      PathOrKey: Fields.countsTowardsOvertime,
      Value: data[Fields.countsTowardsOvertime]
    },
    {
      Operation: "update",
      PathOrKey: Fields.includeInRegularRate,
      Value: data[Fields.includeInRegularRate]
    },
    {
      Operation: "update",
      PathOrKey: Fields.earningGroups,
      Value: Groups
    },
    {
      Operation: "update",
      PathOrKey: Fields.regularRateOfPay,
      Value: data[Fields.regularRateOfPay]
    },
    {
      Operation: "update",
      PathOrKey: Fields.isProductiveHours,
      Value: data[Fields.isProductiveHours]
    },
    {
      Operation: "update",
      PathOrKey: Fields.workersCompensation,
      Value: data[Fields.workersCompensation]
    },
    {
      Operation: "update",
      PathOrKey: Fields.optionsNote,
      Value: data[Fields.optionsNote]
    }
  ];
  if (data[Fields.stepID] < 4) {
    request.push({
      Operation: "update",
      PathOrKey: Fields.stepID,
      Value: 4
    });
    request.push({
      Operation: "update",
      PathOrKey: Fields.stepName,
      Value: "CompanyEarningsOptions"
    });
  }
  updateCompanyEarning(id, request)
    .then(res => {
      const { DataCount, Data = [], Errors = [], ErrorCount, Id } = res.data;
      if (DataCount === 1 && Data[0] != null && ErrorCount === 0) {
        const { UIDraftCompletionStage, CompanyEarningName } = Data[0];
        dispatch(setFormUpdate(false));
        successCallback(Id);
        if (UIDraftCompletionStage < 5) {
          let Toast = `'${CompanyEarningName}' has been saved. We’ve marked this earnings as in progress for you to work on later.`;
          dispatch(
            showToast(Toast, "success", false, "inprogress", "EARNINGS")
          );
        } else {
          let Toast = "'" + CompanyEarningName + "' has been updated. ";
          dispatch(showToast(Toast, "success", false, "default", "EARNINGS"));
        }
      } else {
        let importErrors = Errors.filter(
          error =>
            error.Tag === "IMPORT_CODE_EXIST" ||
            error.Tag === "IMPORT_CODE_EXIST_WITH_EARNINGS"
        );
        if (importErrors.length > 0) {
          dispatch(
            editEntityErrors({
              [Fields.referenceCodeImport]: importErrors[0].MessageList[0]
            })
          );
          dispatch(
            showToast(ERROR_TOAST, RED_TOAST, false, "default", "EARNINGS")
          );
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        }
      }
      dispatch(setLoader(false));
    })
    .catch(error => {
      dispatch(authCheck(error));
    });
};

export const saveEarningandRedirect = (
  id,
  data,
  successCallback
) => dispatch => {
  let request = [];
  if (data[Fields.stepID] < 5) {
    request.push({
      Operation: "create",
      PathOrKey: Fields.stepID,
      Value: 5
    });
  }
  updateCompanyEarning(id, request)
    .then(res => {
      const { DataCount, Data = [], Id } = res.data;
      if (DataCount === 1 && Data[0] != null) {
        successCallback(Id);
        let earningsName = Data[0].CompanyEarningName;
        let isPaid = Data[0][Fields.paidToEmployee];
        if (isPaid) {
          dispatch(
            showToast(
              `'${earningsName}' has been added. Don't forget to map it to your General Ledger accounts.`,
              "success",
              true,
              "EARNINGS"
            )
          );
        } else {
          dispatch(
            showToast(
              `'${earningsName}' has been added.`,
              "success",
              false,
              "EARNINGS"
            )
          );
        }
      }
      dispatch(setLoader(false));
    })
    .catch(error => {
      dispatch(authCheck(error));
    });
};
export const checkIfEarningIsUsed = (
  id,
  sucessCallback = () => { }
) => dispatch => {
  dispatch(setLoader(true));
  checkIfUsedAnywhere(id).then(res => {
    const { Data = [], ErrorCount, Errors = [] } = res.data;
    let Request = [];
    if (ErrorCount === 0 && Data === null) {
      Request[Fields.inUse] = false;
      sucessCallback(false);
    } else {
      sucessCallback(true);
      let errors = Errors.filter(item => item.Tag === "IN_USED");
      dispatch(editEntityErrors({ earnings: errors[0].MessageList }));
    }
    dispatch(setLoader(false));
  });
};

export const startCopy = () => dispatch => {
  startCopyOperation().then(res => { });
};
export const fetchEntitySnapshot = (
  id,
  instance,
  successCallback = () => { }
) => dispatch => {
  dispatch(loadEntitySnapshot({ status: true }));

  getCompanyEarningSnapshot(instance).then(res => {
    const { Data = [] } = res.data;
    if (Data != null && Data.length > 0) {
      dispatch(loadEntitySnapshot({ ...Data[0], status: false }));
      successCallback();
    }
  });
};

export const createAndDispatchToast = (
  data = {},
  callback = () => { }
) => dispatch => {
  const { name, status } = data;

  if (status === "inprogress") {
    let Toast = `'${name}' has been saved. We’ve marked this earnings as in progress for you to work on later.`;
    dispatch(showToast(Toast, "success", false, "inprogress", "EARNINGS"));
  }
  if (status === "active") {
    let Toast = `'${name}' has been saved. We’ve marked this earnings as in progress for you to work on later.`;
    dispatch(showToast(Toast, "success", false, "inprogress", "EARNINGS"));
  }
  callback();
};
